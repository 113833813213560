<template>
  <div
    v-editable="blok"
    class="picture"
    :style="[widthResponsiveStyle]"
    :class="[positionInsideElementClass, pictureWidthClass]"
  >
    <picture v-if="src && src.includes('.svg')">
      <source
        v-if="srcMobile"
        :srcset="srcMobile"
        :media="pictureMedia"
        :alt="altMobile"
      />
      <img
        :src="src"
        :alt="alt"
        :loading="loading"
        class="image image--border-radius"
        :class="isMovableClass"
        :style="[moveXorPaddingStyle, moveYorPaddingStyle, borderRadiusStyle]"
      />
    </picture>
    <BaseNuxtImage
      v-if="src && !src.includes('.svg')"
      :loading="loading"
      :src="src"
      :alt="alt"
      :style="[moveXorPaddingStyle, moveYorPaddingStyle, borderRadiusStyle]"
      :class="[
        'image',
        isMovableClass,
        'image--border-radius',
        {
          'image--desktop-only': srcMobile,
        },
      ]"
    />
    <BaseNuxtImage
      v-if="srcMobile && !src.includes('.svg')"
      :loading="loading"
      :src="srcMobile"
      :alt="alt"
      :style="[moveXorPaddingStyle, moveYorPaddingStyle, borderRadiusStyle]"
      class="image image--border-radius image--mobile"
      :class="isMovableClass"
    />
  </div>
</template>

<script lang="ts" setup>
import type {
  SbPictureStoryblok,
  SbPluginResponsiveSpacingNumbers,
} from '@/types'

import { BREAKPOINT } from '@/data/constants'

interface Props {
  blok: SbPictureStoryblok
  cardPaddingData?: SbPluginResponsiveSpacingNumbers
}

const props = defineProps<Props>()

const src = computed(() => props.blok.image?.filename)
const srcMobile = computed(() => props.blok.image_mobile?.filename)
const alt = computed(() => props.blok.image?.alt ?? 'image')
const altMobile = computed(() => props.blok.image_mobile?.alt ?? 'image')

const pictureMedia = computed(() => `(max-width: ${BREAKPOINT.LG - 1}px)`)

const pictureWidthClass = computed(() =>
  props.blok.force_width ? 'picture__width' : 'picture__max-width',
)

const borderRadiusStyle = computed(() => {
  return {
    '--border-radius-mobile':
      props.blok.border_radius?.mobile !== ''
        ? `${props.blok.border_radius?.mobile}px`
        : '0',
    '--border-radius-tablet':
      props.blok.border_radius?.tablet !== ''
        ? `${props.blok.border_radius?.tablet}px`
        : '0',
    '--border-radius-desktop':
      props.blok.border_radius?.desktop !== ''
        ? `${props.blok.border_radius?.desktop}px`
        : '0',
  }
})

const widthResponsiveStyle = computed(() => {
  return {
    '--width-mobile':
      props.blok.max_width_responsive?.mobile !== ''
        ? `${props.blok.max_width_responsive?.mobile}px`
        : '100%',
    '--width-tablet':
      props.blok.max_width_responsive?.tablet !== ''
        ? `${props.blok.max_width_responsive?.tablet}px`
        : '100%',
    '--width-desktop':
      props.blok.max_width_responsive?.desktop !== ''
        ? `${props.blok.max_width_responsive?.desktop}px`
        : '100%',
  }
})

const moveXorPaddingStyle = computed(() => {
  if (props.cardPaddingData) {
    return {
      '--x-mobile':
        props.blok.move_x?.mobile !== ''
          ? `${props.blok.move_x?.mobile}px`
          : `${props.cardPaddingData.mobile}px`,
      '--x-tablet':
        props.blok.move_x?.tablet !== ''
          ? `${props.blok.move_x?.tablet}px`
          : `${props.cardPaddingData.tablet}px`,
      '--x-desktop':
        props.blok.move_x?.desktop !== ''
          ? `${props.blok.move_x?.desktop}px`
          : `${props.cardPaddingData.desktop}px`,
    }
  }
  return {
    '--x-mobile':
      props.blok.move_x?.mobile !== '' ? `${props.blok.move_x?.mobile}px` : '0',
    '--x-tablet':
      props.blok.move_x?.tablet !== '' ? `${props.blok.move_x?.tablet}px` : '0',
    '--x-desktop':
      props.blok.move_x?.desktop !== ''
        ? `${props.blok.move_x?.desktop}px`
        : '0',
  }
})

const moveYorPaddingStyle = computed(() => {
  if (props.cardPaddingData) {
    return {
      '--y-mobile':
        props.blok.move_y?.mobile !== ''
          ? `${props.blok.move_y?.mobile}px`
          : `${props.cardPaddingData.mobile}px`,
      '--y-tablet':
        props.blok.move_y?.tablet !== ''
          ? `${props.blok.move_y?.tablet}px`
          : `${props.cardPaddingData.tablet}px`,
      '--y-desktop':
        props.blok.move_y?.desktop !== ''
          ? `${props.blok.move_y?.desktop}px`
          : `${props.cardPaddingData.desktop}px`,
    }
  }
  return {
    '--y-mobile':
      props.blok.move_y?.mobile !== '' ? `${props.blok.move_y?.mobile}px` : '0',
    '--y-tablet':
      props.blok.move_y?.tablet !== '' ? `${props.blok.move_y?.tablet}px` : '0',
    '--y-desktop':
      props.blok.move_y?.desktop !== ''
        ? `${props.blok.move_y?.desktop}px`
        : '0',
  }
})

const isMovableClass = computed(() =>
  props.blok.is_movable ? 'image--movable' : '',
)

const positionInsideElementClass = computed(() => {
  return props.blok.position !== undefined
    ? `position__${props.blok.position.position}`
    : ''
})
const loading = computed(() => props.blok.loading || 'lazy')
</script>

<style lang="scss" scoped>
.picture {
  --width-mobile: 100%;
  --width-tablet: 100%;
  --width-desktop: 100%;

  display: flex;
  flex-shrink: 0;

  &__width {
    width: var(--width-mobile);

    @media (min-width: $breakpoint-md) {
      width: var(--width-tablet);
    }

    @media (min-width: $breakpoint-lg) {
      width: var(--width-desktop);
    }
  }

  &__max-width {
    max-width: var(--width-mobile);

    @media (min-width: $breakpoint-md) {
      max-width: var(--width-tablet);
    }

    @media (min-width: $breakpoint-lg) {
      max-width: var(--width-desktop);
    }
  }

  picture {
    width: 100%;
  }
}

.image {
  --x-mobile: 0;
  --x-tablet: 0;
  --x-desktop: 0;
  --y-mobile: 0;
  --y-tablet: 0;
  --y-desktop: 0;
  --border-radius-mobile: 0;
  --border-radius-tablet: 0;
  --border-radius-desktop: 0;

  display: block;
  width: 100%;
  height: 100%;

  &--desktop-only {
    display: none;

    @media (min-width: $breakpoint-lg) {
      display: block;
    }
  }

  &--mobile {
    display: block;

    @media (min-width: $breakpoint-lg) {
      display: none;
    }
  }

  &--border-radius {
    border-radius: var(--border-radius-mobile);

    @media (min-width: $breakpoint-md) {
      border-radius: var(--border-radius-tablet);
    }

    @media (min-width: $breakpoint-lg) {
      border-radius: var(--border-radius-desktop);
    }
  }

  &--movable {
    transform: translate(var(--x-mobile), var(--y-mobile));

    @media (min-width: $breakpoint-md) {
      transform: translate(var(--x-tablet), var(--y-tablet));
    }

    @media (min-width: $breakpoint-lg) {
      transform: translate(var(--x-desktop), var(--y-desktop));
    }
  }
}

.right-bottom {
  margin-left: auto;
  margin-top: auto;
}

.position {
  &__right-top {
    margin-left: auto;
    margin-bottom: auto;
  }

  &__right-bottom {
    margin-left: auto;
    margin-top: auto;
  }

  &__right {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
  }

  &__left-top {
    margin-right: auto;
    margin-bottom: auto;
  }

  &__left-bottom {
    margin-right: auto;
    margin-top: auto;
  }

  &__left {
    margin-top: auto;
    margin-bottom: auto;
    margin-right: auto;
  }

  &__center {
    margin: auto;
  }

  &__top {
    margin-right: auto;
    margin-left: auto;
    margin-bottom: auto;
  }

  &__bottom {
    margin-right: auto;
    margin-left: auto;
    margin-top: auto;
  }

  &__off {
    margin: 0;
  }
}
</style>
